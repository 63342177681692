import "./styles.scss";

import React from "react";

import SectionHeader from "components/SectionHeader";
import FeaturesGrid from "components/FeaturesGrid";

const mainClass = "home-offer";

const data = [
    {
        icon: require("images/offer/icon-1.svg").default,
        text: "podział zysków<br/>70/30",
    },
    {
        icon: require("images/offer/icon-2.svg").default,
        text: "pełna własność<br/>z księgą wieczystą",
    },
    {
        icon: require("images/offer/icon-3.svg").default,
        text: "doświadczony operator<br/>- dla apartamentów z umową<br/>operatorską",
    },
    {
        icon: require("images/offer/icon-4.svg").default,
        text: "inwestycja w popularnym<br/>regionie - ponad 1,5 mln<br/>turystów rocznie",
    },
    {
        icon: require("images/offer/icon-5.svg").default,
        text: "second home w pięknej okolicy<br/>- dla apartamentów bez umowy<br/>operatorskiej",
    },
    {
        icon: require("images/offer/icon-6.svg").default,
        text: "zabezpieczenie kapitału<br/>przed inflacją",
    },
    {
        icon: require("images/offer/icon-7.svg").default,
        text: "zwrot VAT<br/>- także dla os. fizycznych",
    },
];

const Offer = () => {
    return (
        <section id="oferta-section" className={mainClass}>
            <div className="container">
                <SectionHeader
                    header="Oferta"
                    text="Zainwestuj w sercu Karkonoszy, w jednym z najchętniej odwiedzanych rejonów turystycznych w Polsce, gdzie sezon trwa cały rok. Przy zakupie apartamentu z obsługą operatorską otrzymujesz aż 70% dochodu bez zaangażowania czasowego po swojej stronie. Estymowany zwrot z inwestycji, to 8% w skali roku."
                />
                <div style={{ width: "100%" }}>
                    <FeaturesGrid data={data} />
                </div>
            </div>
        </section>
    );
};

export default Offer;
