import "./styles.scss";
import React from "react";

import SectionHeader from "components/SectionHeader";

const mainClass = "home-buy-process";

const process_data = [
    {
        icon: require("images/facilities/agreement.svg").default,
        text: "podpisanie umowy",
    },
    {
        icon: require("images/facilities/withdraw.svg").default,
        text: "wpłata zadatku",
    },
    {
        icon: require("images/facilities/deal.svg").default,
        text: "umowa przedwstępna",
    },
    {
        icon: require("images/facilities/calendar-money.svg").default,
        text: "płatności wg harmonogramu",
    },
    {
        icon: require("images/facilities/collect.svg").default,
        text: "odbiór lokalu ",
    },
    {
        icon: require("images/facilities/hands.svg").default,
        text: "nadanie własności pod nadzorem notarialnym",
    },
];

const BuyProcess = () => {
    return (
        <section id="proces-zakupu-section" className={mainClass}>
            <div className="container">
                <SectionHeader header="Proces zakupu" />
                <div className={`${mainClass}__items`}>
                    {process_data?.map(({ icon, text }, index) => (
                        <div key={index} className={`${mainClass}__item`}>
                            <div className={`${mainClass}__item__wrapper`}>
                                <div className={`${mainClass}__item__icon`}>
                                    <img src={icon} alt="" />
                                </div>
                                <h2>{index + 1}</h2>
                                <span>{text}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BuyProcess;
