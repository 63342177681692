import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "../components/Layout/components/Seo";
import {
  Hero,
  AboutInvestment,
  Offer,
  Apartments,
  Facilities,
  Location,
  Operator,
  BuyProcess,
  Gallery,
} from "page_components/home";

import scrollToElement from "utils/scrollToElement";

const Home = ({ location, data }) => {
  const { hash, pathname, search } = location;

  const [init_loading, setInitLoading] = useState(false);

  useEffect(() => {
    if (!!!hash) return;

    if (!!init_loading) {
      scrollToElement(`${hash.split("?")[0]}-section`);
    } else {
      setTimeout(() => {
        scrollToElement(`${hash.split("?")[0]}-section`);
      }, 100);
    }

    setInitLoading(true);
    // eslint-disable-next-line
  }, [location]);

  return (
    <Layout static_header={pathname === "/"} search={search}>
      <Hero search={search} />
      <AboutInvestment />
      <Offer />
      <Apartments data={data?.allApartments?.nodes} />
      <Facilities />
      <Location />
      <BuyProcess />
      <Operator />
      <Gallery />
      {/* <Gallery />
      <BusinessModel />
      <Steps /> */}
      {/* <Calculator /> */}
      {/* <Baner />
      <WhyIsWorth />
      <Operator /> */}
    </Layout>
  );
};

export const Head = () => (
  <Seo
    title="Kameralna Dolina – apartamenty inwestycyjne w Szklarskiej Porębie."
    description="Zainwestuj w gotowy apartament i zarabiaj na wynajmie nawet 8% rocznie w skali roku. Sprawdź!"
  />
);

export default Home;

export const query = graphql`
  query {
    allApartments: allWpNieruchomosc {
      nodes {
        id
        title
        nieruchomosci {
          nameBuilding
          floor
          balkon
          taras
          area
          rooms
          statusId
          cardLink
          photoLink1
        }
      }
    }
  }
`;
