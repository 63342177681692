import "./styles.scss";

import React from "react";

import SectionHeader from "components/SectionHeader";

import Operator1Image from "images/operator1.jpg";

const mainClass = "home-operator";

const Operator = () => {
    return (
        <section id="deweloper-section" className={mainClass}>
            <div className="container">
                <SectionHeader
                    header="Deweloper i operator"
                    text="Gwarancja sukcesu i rentowności projektu!"
                />
                <div className={`${mainClass}__post`}>
                    <div className={`${mainClass}__post__image`}>
                        <img src={Operator1Image} alt="" />
                    </div>
                    <div className={`${mainClass}__post__text`}>
                        <p className="medium">
                            Za inwestycję odpowiada Szklarska Apartments,
                            doświadczony deweloper z ponad 11-letnim
                            doświdczeniem na rynku inwestycyjnym. Apartament
                            można nabyć na cele własne mieszkaniowe bądź zlecić
                            jego zarządzanie grupie hotelowej.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Operator;
