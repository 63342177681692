import "./styles.scss";

import React, { useState } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";

import SectionHeader from "components/SectionHeader";

import Slide1 from "images/gallery/zdjecie_1.jpg";
import Slide2 from "images/gallery/zdjecie_2.jpg";
import Slide3 from "images/gallery/zdjecie_3.jpg";
import Slide4 from "images/gallery/zdjecie_4.jpg";
import Slide5 from "images/gallery/zdjecie_5.jpg";
import Slide6 from "images/gallery/zdjecie_6.jpg";
import Slide7 from "images/gallery/zdjecie_7.jpg";
import Slide8 from "images/gallery/zdjecie_8.jpg";
import Slide9 from "images/gallery/zdjecie_9.jpg";
import Slide10 from "images/gallery/zdjecie_10.jpg";
import Slide11 from "images/gallery/zdjecie_11.jpg";
import Slide12 from "images/gallery/zdjecie_12.jpg";
import Slide13 from "images/gallery/zdjecie_13.jpg";
import Slide14 from "images/gallery/zdjecie_14.jpg";
import Slide15 from "images/gallery/zdjecie_15.jpg";
import Slide16 from "images/gallery/zdjecie_16.jpg";
import Slide17 from "images/gallery/zdjecie_17.jpg";
import Slide18 from "images/gallery/zdjecie_18.jpg";
import Slide19 from "images/gallery/zdjecie_19.jpg";
import Slide20 from "images/gallery/zdjecie_20.jpg";
import Slide21 from "images/gallery/zdjecie_21.jpg";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-gallery";

function CustomArrow({ onClick, className, styles }) {
    return (
        <div
            className={`slick-arrow ${className}`}
            style={styles}
            onClick={onClick}
            role="presentation"
        >
            <ArrowIcon />
        </div>
    );
}

const Gallery = () => {
    const [current_slide, setCurrentSlide] = useState(0);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const items = [
        {
            localFile: {
                publicURL: Slide1,
            },
        },
        {
            localFile: {
                publicURL: Slide2,
            },
        },
        {
            localFile: {
                publicURL: Slide3,
            },
        },
        {
            localFile: {
                publicURL: Slide4,
            },
        },
        {
            localFile: {
                publicURL: Slide5,
            },
        },
        {
            localFile: {
                publicURL: Slide6,
            },
        },
        {
            localFile: {
                publicURL: Slide7,
            },
        },
        {
            localFile: {
                publicURL: Slide8,
            },
        },
        {
            localFile: {
                publicURL: Slide9,
            },
        },
        {
            localFile: {
                publicURL: Slide10,
            },
        },
        {
            localFile: {
                publicURL: Slide11,
            },
        },
        {
            localFile: {
                publicURL: Slide12,
            },
        },
        {
            localFile: {
                publicURL: Slide13,
            },
        },
        {
            localFile: {
                publicURL: Slide14,
            },
        },
        {
            localFile: {
                publicURL: Slide15,
            },
        },
        {
            localFile: {
                publicURL: Slide16,
            },
        },
        {
            localFile: {
                publicURL: Slide17,
            },
        },
        {
            localFile: {
                publicURL: Slide18,
            },
        },
        {
            localFile: {
                publicURL: Slide19,
            },
        },
        {
            localFile: {
                publicURL: Slide20,
            },
        },
        {
            localFile: {
                publicURL: Slide21,
            },
        },
    ];

    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        lazyLoad: true,
        arrows: true,
        draggable: true,
        useTransform: true,
        // centerMode: true,
        nextArrow: <CustomArrow className="slick-next" />,
        prevArrow: <CustomArrow className="slick-prev" />,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex);
        },
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className={mainClass} id="galeria-section">
            <SectionHeader header="Galeria" />
            <div className={`${mainClass}__wrapper`}>
                <div className={`${mainClass}__wrapper__box`}>
                    <Slider {...sliderSettings}>
                        {items?.map((item, index) => (
                            <div
                                key={index}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsLightboxOpen(true);
                                    setPhotoIndex(index);
                                }}
                                role="presentation"
                            >
                                <div
                                    className={`${mainClass}__slide`}
                                    style={{
                                        backgroundImage: `url(${item?.localFile?.publicURL})`,
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
                <span className={`${mainClass}__wrapper__pagination`}>
                    {current_slide + 1}/{items?.length}
                </span>
            </div>
            {isLightboxOpen && (
                <Lightbox
                    mainSrc={items[photoIndex]?.localFile?.publicURL}
                    nextSrc={items[(photoIndex + 1) % items.length]}
                    prevSrc={
                        items[(photoIndex + items.length - 1) % items.length]
                    }
                    onCloseRequest={() => setIsLightboxOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + items.length - 1) % items.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % items.length)
                    }
                />
            )}
        </section>
    );
};

export default Gallery;
