import "./styles.scss";

import React from "react";
import SectionHeader from "components/SectionHeader";

const mainClass = "home-about-investment";

const items_data = [
    {
        count: 2,
        label: "50,15",
    },
    {
        count: 6,
        label: "51,90",
    },
    {
        count: 4,
        label: "52,05",
    },
];

const AboutInvestment = () => {
    return (
        <section id="o-inwestycji-section" className={mainClass}>
            <div className="container">
                <SectionHeader
                    is_reactangle
                    color="pink"
                    header="O inwestycji"
                    text="Witamy w Kameralnej Dolinie - ukrytym klejnocie Szklarskiej Poręby. To tutaj, w sercu malowniczych Karkonoszy, przy spokojnej i widokowej ul. Kołłątaja oferujemy wyjątkowe doświadczenie: oto marzenia o stworzeniu własnej oazy spokoju spotykają się mądrością trafnych wyborów inwestycyjnych. Kupując apartament w Kameralnej Dolinie otrzymujesz bezpieczną lokatę kapitału, pewny i stały zwrot oraz własny azyl w jednym z najpiękniejszych zakątków Polski."
                />
                <div className={`${mainClass}__content`}>
                    <div className={`${mainClass}__content__banner`}>
                        <div className={`${mainClass}__content__banner__text`}>
                            <div
                                className={`${mainClass}__content__banner__text__content`}
                            >
                                <h5>
                                    Wyjątkowy "second home" lub pewna inwestycja
                                    z estymowanym 8% zwrotem w skali roku
                                </h5>
                                <p className="medium">
                                    Inwestując w Kameralną Dolinę otrzymujesz
                                    nie tylko możliwość cieszenia się
                                    apartamentem z własnym ogródkiem i tarasem z
                                    widokiem na oszałamiającą panoramę
                                    Karkonoszy, ale także skuteczne narzędzie do
                                    pomnażania kapitału i ochrony przed
                                    inflacją. Zainwestuj z nami w jednym z
                                    najchętniej odwiedzanych regionów
                                    turystycznych w Polsce, gwarantującym stały
                                    napływ gości przez cały rok.
                                </p>
                            </div>
                        </div>
                        <div
                            className={`${mainClass}__content__banner__image`}
                        />
                    </div>
                    <div className={`${mainClass}__content__info`}>
                        <p>
                            Ta wyjątkowa inwestycja położona przy ul. Kołłątaja
                            w Szklarskiej Porębie obejmuje 12 przestronnych,
                            dwupokojowych apartamentów z tarasami lub balkonami,
                            usytuowanych łącznie w trzech domach z pięknym
                            widokiem na Karkonosze. Dostępne metraże:
                        </p>
                        <div className={`${mainClass}__content__info__items`}>
                            {items_data?.map(({ count, label }, index) => (
                                <div
                                    key={index}
                                    className={`${mainClass}__content__info__items__item`}
                                >
                                    <h2>
                                        <span>{count}x </span>
                                        {label} m<sup>2</sup>
                                    </h2>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutInvestment;
