const scrollToElement = (element) => {
  // const window_width = window.innerWidth;
  const current_offset = 80;

  if (element === "/") {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  } else {
    const elem = document.querySelector(element);

    window.scrollBy({
      top: elem.getBoundingClientRect().top - current_offset,
      behavior: "smooth",
    });
  }
};

export default scrollToElement;
