import "./styles.scss";

import React from "react";

import SectionHeader from "components/SectionHeader";

import Facilities1Image from "images/facilities1.jpg";

const mainClass = "home-facilities";

const Facilities = () => {
    return (
        <section id="udogodnienia-section" className={mainClass}>
            <div className="container">
                <SectionHeader header="Udogodnienia i atuty" />
                <div className={`${mainClass}__post`}>
                    <div className={`${mainClass}__post__image`}>
                        <img src={Facilities1Image} alt="" />
                    </div>
                    <div className={`${mainClass}__post__text`}>
                        <h5>Natura i Elegancja</h5>
                        <p className="medium">
                            Kameralna Dolina to skąpana w zieleni oaza spokoju,
                            która zachwyca urodą, widokami i spokojem. Trzy
                            eleganckie budynki inspirowane lokalną architekturą,
                            harmonijnie współgrają z otaczającym krajobrazem,
                            tworząc wymarzoną przestrzeń na relaks i wypoczynek.
                        </p>
                    </div>
                </div>
                <div className={`${mainClass}__banner`}>
                    <div className={`${mainClass}__banner__text`}>
                        <div className={`${mainClass}__banner__text__content`}>
                            <h5>Sielankowa okolica</h5>
                            <p className="medium">
                                Pozwól sobie na chwilę oderwania od codziennego
                                pędu i zgiełku. Kameralna Dolina jest
                                umiejscowiona w sercu Karkonoszy, w sąsiedztwie
                                lasów, gór, wodospadów i strumieni. Kto raz
                                skosztuje tych urzekających cudów natury, będzie
                                tu wracał przez długie lata.
                            </p>
                        </div>
                    </div>
                    <div className={`${mainClass}__banner__image`} />
                </div>
                <div className={`${mainClass}__bottom-text`}>
                    <h5>Zapierające dech w piersi widoki</h5>
                    <p className="medium">
                        Marzyłeś kiedyś o tym, aby każdego ranka budzić się z
                        widokiem na otaczające Cię górskie szczyty? Rozciągające
                        się w każdą stronę piękne widoki odpowiedzą na potrzeby
                        najbardziej wymagających gości Kameralnej Doliny.
                    </p>
                </div>
            </div>
            <div className={`${mainClass}__image`} />
            <div className={`${mainClass}__invitation`}>
                <div className="container">
                    <div className={`${mainClass}__invitation__left`}>
                        <div className={`${mainClass}__invitation__left__text`}>
                            <h5>Apartamenty bliżej Twoich potrzeb</h5>
                            <p className="medium">
                                Kameralna Dolina to nie tylko tarasy, ogródki i
                                użyteczna przestrzeń dla tych, którzy cenią
                                sobie odpoczynek i napawanie wzroku kojącymi
                                okolicznościami przyrody bez wychodzenia z domu.
                                To również znakomita, świetnie położona baza
                                wypadowa dla osób marzących o aktywnym
                                wypoczynku, zdobywaniu okolicznych szczytów i
                                korzystaniu z szerokiej palety możliwości
                                sportowych i rekreacyjnych w regionie,
                                dostępnych przez cztery pory roku.
                            </p>
                        </div>
                    </div>
                    <div className={`${mainClass}__invitation__right`}>
                        <h4>
                            Zapraszamy do kontaktu i bliższego poznania naszego
                            projektu już dziś!
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Facilities;
