import "./styles.scss";

import React from "react";

import ContactForm from "components/ContactForm";
import Button from "components/Button";

const mainClass = "home-hero";

const Hero = ({search}) => {
  return (
    <section className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__left`}>
          <h3>Kameralna Dolina</h3>
          <h1>Twój Azyl w Sercu Karkonoszy</h1>
          <span>
            Zainwestuj w gotowy apartament i zarabiaj na wynajmie nawet 8%
            rocznie w skali roku.
          </span>
          <Button variant="pink" href="#o-inwestycji">
            Więcej o inwestycji
          </Button>
        </div>
        <ContactForm header="Formularz" search={search} />
      </div>
    </section>
  );
};

export default Hero;
