import React from "react";

import MuseumIcon from "icons/museum.svg";
import NaturalIcon from "icons/natural.svg";
import SportsIcon from "icons/sports.svg";
import CitiesIcon from "icons/cities.svg";

const legend_data = [
  {
    icon: <MuseumIcon />,
    label: "Zabytki i muzea",
    variant: "white",
    items: [
      {
        name: "Multimedialne Muzeum Karkonoszy",
        lat: 50.773590824201506,
        lng: 15.73409581094916,
      },
      {
        name: "Ruiny zamku Bolczów",
        lat: 50.86195064520434,
        lng: 15.911468297948543,
      },
      {
        name: "Ruiny zamku Chojnik",
        lat: 50.83404149561858,
        lng: 15.644230869328899,
      },
      {
        name: "Wieża Książęca w Siedlęcinie",
        lat: 50.9353245043647,
        lng: 15.685467619471648,
      },
      {
        name: "Muzeum Przyrodnicze w Cieplicach",
        lat: 50.865579222347534,
        lng: 15.677779598165404,
      },
      {
        name: "Muzeum Ziemi Juna",
        lat: 50.82982285984943,
        lng: 15.543022824018578,
      },
      {
        name: "Kościół Wang w Karpaczu",
        lat: 50.77741989016949,
        lng: 15.724088369326813,
      },
      {
        name: "Zamek księcia Henryka",
        lat: 50.835999911843324,
        lng: 15.723120540261636,
      },
      {
        name: "Zamek Wleń",
        lat: 51.017584114574866,
        lng: 15.662488808300457,
      },
      {
        name: "Zamek Kapniki Schloss Fischbach",
        lat: 50.846620866396954,
        lng: 15.849764653987807,
      },
      {
        name: "Muzeum Karkonoskie",
        lat: 50.89740733551627,
        lng: 15.73840713413768,
      },
      {
        name: "Pałac Wojanów",
        lat: 50.87538636956579,
        lng: 15.813756740495,
      },
      {
        name: "Zamek Bolków",
        lat: 50.92177350605047,
        lng: 16.097847240496648,
      },
      {
        name: "Zamek Czocha",
        lat: 51.03087519038719,
        lng: 15.303651898171482,
      },
    ],
  },
  {
    icon: <NaturalIcon />,
    label: "Atrakcje przyrodnicze",
    variant: "green",
    items: [
      {
        name: "Śnieżka",
        lat: 50.737052923766505,
        lng: 15.739977162524378,
      },
      {
        name: "Wodospad Kamieńczyka",
        lat: 50.813761221675485,
        lng: 15.496849455834257,
      },
      {
        name: "Szrenica",
        lat: 50.79256859368455,
        lng: 15.513111841587527,
      },
      {
        name: "Śnieżne Kotły",
        lat: 50.77810734615423,
        lng: 15.557952926997602,
      },
      {
        name: "Wodospad Szklarki",
        lat: 50.82942715378958,
        lng: 15.553592083393072,
      },
      {
        name: "Wodospad Łomniczki",
        lat: 50.74276378601181,
        lng: 15.732479526996292,
      },
      {
        name: "Wysoki Kamień",
        lat: 50.845878631016376,
        lng: 15.4921388183265,
      },
      {
        name: "Wodospad Kropelka",
        lat: 50.857103787372715,
        lng: 15.561213313506627,
      },
      {
        name: "Jaskinia Ucho Igielne",
        lat: 50.85268309892796,
        lng: 15.73561442700027,
      },
    ],
  },
  {
    icon: <SportsIcon />,
    label: "Sport i rekreacja",
    variant: "black",
    items: [
      {
        name: "Szrenica Ski Arena",
        lat: 50.8182353323281,
        lng: 15.517403682822152,
      },
      {
        name: "Karpacz Ski Arena",
        lat: 50.78281576982795,
        lng: 15.750128335023676,
      },
      {
        name: "Szlak turystyczny na Śnieżkę",
        lat: 50.74155426626676,
        lng: 15.70331166547498,
      },
      {
        name: "Szlak turystyczny na Śnieżne Kotły",
        lat: 50.78136646892732,
        lng: 15.550572329632939,
      },
      {
        name: "Park Miniatur Zabytków Dolnego Śląska w Kowarach",
        lat: 50.79873240217337,
        lng: 15.834201311656766,
      },
      {
        name: "Singletracki Szklarska Poręba - Jakuszyce",
        lat: 50.82088585032171,
        lng: 15.437197698596458,
      },
      {
        name: "Wieża Sky Walk",
        lat: 50.89997884170296,
        lng: 15.33701626933126,
      },
      {
        name: "Polana Jakuszycka - Dolnośląskie Centrum Sportu",
        lat: 50.815358017735804,
        lng: 15.429321540492786,
      },
      {
        name: "Park Linowy Trollandia",
        lat: 50.853208608216406,
        lng: 15.525013620285977,
      },
    ],
  },
  {
    icon: <CitiesIcon />,
    label: "Miasta",
    variant: "gold",
    items: [
      {
        name: "Karpacz - 30 min",
        lat: 50.7644049,
        lng: 15.7443079,
      },
      {
        name: "Warszawa - 5 h",
        lat: 52.232938,
        lng: 21.0611941,
      },
      {
        name: "Wrocław - 2h",
        lat: 51.1270779,
        lng: 16.9918639,
      },
      {
        name: "Praga - 2,5 h",
        lat: 50.0596696,
        lng: 14.4656239,
      },
      {
        name: "Drezno - 2,5 h",
        lat: 51.0768337,
        lng: 13.7725857,
      },
    ],
  },
];

export default legend_data;
