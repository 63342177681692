import "./styles.scss";
import React from "react";

import LocationMap from "components/LocationMap";
import SectionHeader from "components/SectionHeader";

import legend_data from "libs/legend_data";

const mainClass = "home-location";

const Location = () => {
  return (
    <section className={mainClass} id="lokalizacja-section">
      <div className="container">
        <SectionHeader
          header="Lokalizacja"
          text="Położona w Szklarskiej Porębie Kameralna Dolina wyróżnia się spokojną i kameralną okolicą, spektakularnymi widokami na okoliczne Karkonosze i Izery oraz bogactwem pobliskich atrakcji sportowych, kulturalnych i przyrodniczych, interesujących zarówno dla rodzin z dziećmi, jak i dorosłych."
        />
        <div className={`${mainClass}__map`}>
          <div className={`${mainClass}__map__image`}>
            <LocationMap />
          </div>
          <div className={`${mainClass}__map__legend`}>
            {legend_data?.map(({ icon, label, items }, index) => (
              <div key={index} className={`${mainClass}__map__legend__col`}>
                {icon}
                <div className={`${mainClass}__map__legend__col__list`}>
                  <label className="medium">{label}</label>
                  <ul>
                    {items?.map(({ name }, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`${mainClass}__banner`}>
          <div className={`${mainClass}__banner__text`}>
            <div className={`${mainClass}__banner__text__content`}>
              <h3>Atuty lokalizacji</h3>
              <ul>
                <li className="medium">
                  Ponad 50 zabytków i muzeów w regionie
                </li>
                <li className="medium">
                  Wodospady i górskie szczyty na wyciągnięcie ręki
                </li>
                <li className="medium">
                  Blisko 30 wyciągów narciarskich w okolicy
                </li>
                <li className="medium">Liczne trasy rowerowe</li>
                <li className="medium">
                  Kilkaset km szlaków pieszych i narciarsko-biegowych
                </li>
              </ul>
            </div>
          </div>
          <div className={`${mainClass}__banner__image`} />
        </div>
      </div>
    </section>
  );
};

export default Location;
