import "./styles.scss";
import "react-image-lightbox/style.css";

import React, { useState } from "react";
import classnames from "classnames";
import Lightbox from "react-image-lightbox";

import SectionHeader from "components/SectionHeader";

import CardIcon from "icons/card.svg";
import PhotoIcon from "icons/photo.svg";

const mainClass = "home-apartments";

const columns_data = [
  "Nr lokalu",
  "Piętro",
  "Metraż",
  "Balkon",
  "Taras na gruncie",
  "Pokoje",
  "Status",
  "Karta lokalu",
  "Widok",
  "Cena",
];

const status_render = (status) => {
  switch (status) {
    case "1":
      return "wolny";
    case "2":
      return "zarezerwowany";
    case "3":
    case "4":
    case "5":
    case "6":
    case "7":
    case "8":
      return "sprzedany";
    case "9":
      return "wstrzymany";
    default:
      return null;
  }
};

const Apartments = ({ data }) => {
  const [photo_open, setPhotoOpen] = useState(null);

  const formatted_data = data.sort(function (a, b) {
    return a.nieruchomosci?.nameBuilding === b.nieruchomosci?.nameBuilding
      ? 0
      : a.nieruchomosci?.nameBuilding < b.nieruchomosci?.nameBuilding
      ? -1
      : 1;
  });

  return (
    <section id="apartamenty-section" className={mainClass}>
      <div className="container">
        <SectionHeader header="Wybierz apartament" />
        <div className={`${mainClass}__list__wrapper`}>
          <div className={`${mainClass}__list`}>
            <div
              className={classnames(
                `${mainClass}__list__row`,
                `${mainClass}__list__row--header`
              )}
            >
              {columns_data?.map((item, index) => (
                <div key={index} className={`${mainClass}__list__row__col`}>
                  <span className="small">{item}</span>
                </div>
              ))}
            </div>
            <div className={`${mainClass}__list__content`}>
              {formatted_data?.map(({ id, nieruchomosci }) => {
                const status = status_render(nieruchomosci?.statusId);

                return (
                  <div
                    key={id}
                    className={classnames(`${mainClass}__list__row`)}
                  >
                    <div className={`${mainClass}__list__row__col`}>
                      <span className="medium">
                        {nieruchomosci?.nameBuilding}
                      </span>
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      <span className="medium">
                        {nieruchomosci?.floor || 0}
                      </span>
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      <span className="medium">{nieruchomosci?.area}</span>
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      <span className="medium">
                        {!!nieruchomosci?.balkon ? "tak" : "nie"}
                      </span>
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      <span className="medium">
                        {!!nieruchomosci?.taras ? "tak" : "nie"}
                      </span>
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      <span className="medium">{nieruchomosci?.rooms}</span>
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      <span className="medium">{status}</span>
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      {status !== "sprzedany" ? (
                        <a
                          href={nieruchomosci?.cardLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="medium"
                        >
                          pobierz kartę
                          <CardIcon />
                        </a>
                      ) : null}
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      {status !== "sprzedany" && !!nieruchomosci?.photoLink1 ? (
                        <span
                          className="medium"
                          role="presentation"
                          onClick={() =>
                            setPhotoOpen(nieruchomosci?.photoLink1)
                          }
                        >
                          zobacz
                          <PhotoIcon />
                        </span>
                      ) : null}
                    </div>
                    <div className={`${mainClass}__list__row__col`}>
                      {status !== "sprzedany" ? (
                        <a
                          className={`${mainClass}__list__row__col--button`}
                          href={`/#kontakt?apartment=${id}`}
                        >
                          Zapytaj o cenę
                        </a>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {!!photo_open && (
        <Lightbox
          mainSrc={photo_open}
          onCloseRequest={() => setPhotoOpen(null)}
        />
      )}
    </section>
  );
};

export default Apartments;
