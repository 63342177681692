import "./styles.scss";

import React from "react";

const mainClass = "features-grid";

const FeaturesGrid = ({ data }) => {
    return (
        <div className={mainClass}>
            {data?.map(({ icon, text }, index) => (
                <div key={index} className={`${mainClass}__item`}>
                    <div className={`${mainClass}__item-icon`}>
                        <img src={icon} alt="" />
                    </div>
                    <div
                    className={`${mainClass}__item-text`}
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export default FeaturesGrid;
